import { onCopy } from "./onCopy";
// import { constants } from "constants";

export const onShare = (link) => {
  // const { brand } = constants
  return onCopy(link)
        // if (navigator.share) {
        //  return navigator
        //     .share({
        //       title: brand,
        //       url: link,
        //     })
        //     .then(() => { return })
        //     .catch(() => { return });
        // } else {
        //    return onCopy(link)
        // }
    
}